import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import * as appConsts from '../../../../store/consts'
import API from '../../../../modules/api'
import Util from "../../../../modules/utils";
import './HomeLastCourse.css'
function HomeLastCourse() {
    const utl = new Util();
    const [showElement, setShowElement] = useState(true);
    const [selectedVideoState, setSelectedVideoState] = useState(null);
    const [showVideoPlayerState, setShowVideoPlayerState] = useState(false);

    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const [homeState, setHomeState] = useState({
        title: "Read latest story",
        btn_title: "مشاهده بیشتر",
        dsc: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        videos: []

    })
    useEffect(() => {
        loadData();

    }, [lang])

    useEffect(() => {
        loadData();

    }, [])


    const loadData = () => {

        let arrNames = ['home-videos', 'home-videos-show'];

        let obj = { ...homeState };
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
                if (result['home-videos-show'] != null) {
                    setShowElement((result['home-videos-show'] == 'true'))
                }

                if (result['home-videos'] != null) {
                    const myObj = JSON.parse(result['home-videos']);
                    try {
                        tranlates.then(result2 => {
                            myObj.title = utl.translateWord(result2, myObj.title);
                            myObj.dsc = utl.translateWord(result2, myObj.dsc);
                            myObj.btn_title = utl.translateWord(result2, myObj.btn_title);
                            obj = { ...myObj }
                            setHomeState({ ...homeState, ...obj });
                        });

                    } catch (error) {
                        obj = { ...myObj }
                        setHomeState({ ...homeState, ...obj });
                    }
                    console.log("WERTY", myObj.videos)



                }
                console.log("WERTY", obj)

            });

        } catch (error) {

            console.log("ERR", error)
        }



    }



    const handlePlayClicked = (event, item) => {
        event.preventDefault();
        setSelectedVideoState(item.video);
        setShowVideoPlayerState(true);
    }
    const handlePlayerCloseClicked = (event) => {
        event.preventDefault();
        setSelectedVideoState(null);
        setShowVideoPlayerState(false);
    }

    return (
        <section className="row_am latest_story" id="blog">
            <div className="container">
                <div className="section_title aos-init" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                    <h2>{homeState.title}</h2>
                    <p>{homeState.dsc}</p>
                </div>

                <div className="row">


                    {
                        homeState.videos.map((item, index) => {
                            return <div key={`vvvddd${index}`} className="col-md-4 sob-item-video">
                              

                                <div className="story_box aos-init" data-aos="fade-up" data-aos-duration="1500">
                                <div className='sob-home-video-player-container'>
                                
                                <a href={item.link} onClick={(event) => handlePlayClicked(event, item)} className="btn text_btn">
                                    
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="90"  height="90"  viewBox="0 0 24 24"  fill="currentColor"  class="icon icon-tabler icons-tabler-filled icon-tabler-player-play"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z" /></svg>


                                </a>

                                </div>
                                    <div className="story_img">
                                        <img src={`${appConsts.api_url}/api/attachments/file/${item.src}`} alt="image" />
                                        {/* <span><span>23</span> AUG</span> */}
                                    </div>
                                    <div className="story_text">
                                        {/* <div className="statstic">
            <span><i className="icofont-user-suited"></i> Admin</span>
            <span><i className="icofont-speech-comments"></i> 36 Comments</span>
        </div> */}
                                        <h3>{item.title}</h3>
                                        <p>{item.dsc}</p>
                                        {/* <a href={item.link}  className="btn text_btn">{homeState.btn_title} <i className="icofont-play"></i></a> */}
                                    </div>
                                </div>
                                {

                                    showVideoPlayerState ?
                                        <div id="videoModal" class="sob-home-video-player-modal">
                                            <div class="modal-content">
                                                <span id="closeModal" onClick={handlePlayerCloseClicked} class="sob-home-video-player-close">&times;</span>

                                                <video controls>
                                                    <source src={`${appConsts.api_url}/api/attachments/file/${selectedVideoState}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div> : null
                                }


                            </div>
                        })
                    }


                    {/* 
            <div className="col-md-4">
                <div className="story_box aos-init" data-aos="fade-up" data-aos-duration="1500">
                    <div className="story_img">
                        <img src="/assets/images/story-02.png" alt="image" />
                        <span><span>18</span> AUG</span>
                    </div>
                    <div className="story_text">
                        <div className="statstic">
                            <span><i className="icofont-user-suited"></i> Admin</span>
                            <span><i className="icofont-speech-comments"></i> 36 Comments</span>
                        </div>
                        <h3>Why software is globally used as best software</h3>
                        <p>Simply dummy text of the printing and typesetting industry lorem Ipsum has Lorem Ipsum is.</p>
                        <a href="https://kalanidhithemes.com/live-preview/landing-page/codely/all-demo/01-codely-landing-page-defoult/blog-detail.html" className="btn text_btn">READ MORE <i className="icofont-arrow-right"></i></a>
                    </div>
                </div>
            </div>


            <div className="col-md-4">
                <div className="story_box aos-init" data-aos="fade-up" data-aos-duration="1500">
                    <div className="story_img">
                        <img src="/assets/images/story-03.png" alt="image" />
                        <span><span>9</span> AUG</span>
                    </div>
                    <div className="story_text">
                        <div className="statstic">
                            <span><i className="icofont-user-suited"></i> Admin</span>
                            <span><i className="icofont-speech-comments"></i> 36 Comments</span>
                        </div>
                        <h3>Beautiful user interface with bug free code.</h3>
                        <p>Printing and typesetting industry lorem Ipsum has Lorem simply dummy text of the.</p>
                        <a href="https://kalanidhithemes.com/live-preview/landing-page/codely/all-demo/01-codely-landing-page-defoult/blog-detail.html" className="btn text_btn">READ MORE <i className="icofont-arrow-right"></i></a>
                    </div>
                </div>
            </div> */}
                </div>

            </div>

        </section>

    )
}

export default HomeLastCourse