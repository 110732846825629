import React, { useEffect, useState, useRef, useCallback } from 'react'
import Contact1 from '../../../components/site/ContactUs/Contact1'
import Contact2 from '../../../components/site/ContactUs/Contact2'
import Contact3 from '../../../components/site/ContactUs/Contact3'
import {  useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
import API from '../../../modules/api'

function ContactUs() {
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(addChangePageTitle("تماس با ما"))
    
        })
        const lang = useSelector((state) => state.lang)
        const [controlsStates, setControlsStates] = useState([
            { title: "تلفن", name: "tel", type: "sample" ,icon:"./assets/images/phone.png"},
            { title: "آدرس", name: "address", type: "sample" ,icon:"./assets/images/location.png"},
            { title: "ایمیل", name: "email", type: "sample" ,icon:"./assets/images/mail.png"},
            { title: "فرم", name: "form", type: "form" },
            { title: "نقشه", name: "map", type: "map" },
    
        ])
        const [textStates, setTextStates] = useState({});
        useEffect(()=>{loadData();},[])
        useEffect(()=>{loadData();},[lang])


        const loadData = () => {

            let arrNames = [];
    
            controlsStates.map((control) => {
    
                if (control.type == "sample") {
                    const names = ['title', 'dsc', 'data']
                    names.map((item2) => {
                        const name = `contactus_${control.name}_${item2}_${lang}`
                        if (arrNames.indexOf(name) == -1) {
                            arrNames = [...arrNames, name];
                        }
                    })
                }
                if (control.type == "form") {
                    const names = ['title', 'dsc', 'name', 'email', 'country', 'message', 'button']
                    names.map((item2) => {
                        const name = `contactus_${control.name}_${item2}_${lang}`
                        if (arrNames.indexOf(name) == -1) {
                            arrNames = [...arrNames, name];
                        }
                    })
                }
                if (control.type == "map") {
                    const names = ['link']
                    names.map((item2) => {
                        const name = `contactus_${control.name}_${item2}_${lang}`
                        if (arrNames.indexOf(name) == -1) {
                            arrNames = [...arrNames, name];
                        }
                    })
                }

            })
            console.log('ABC', arrNames)
            let obj = { ...textStates };
            try {
    
                (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                    console.log("TRE", result)
                    arrNames.forEach((item) => {
                        if (result[item] != null) {
                            obj[item] = result[item];
                        }
                    })
    
    
    
                    console.log("WERTY", obj)
                    setTextStates({ ...textStates, ...obj });
                });
    
            } catch (error) {
    
                console.log("ERR", error)
            }
    
    
    
        }
    

    return (
        <>



            <Contact1 controls={controlsStates} data={textStates} lang={lang}/>
            <Contact2 data={textStates} lang={lang}/>
            <Contact3 data={textStates} lang={lang}/>

        </>
    )
}

export default ContactUs