import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'; 

function Contact3({data,lang}) {

  const[mapState,setMapState]=useState(`<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d42349.26187294995!2d51.350384631184134!3d35.70505694241849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfa!2s!4v1733637659330!5m2!1sfa!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`)
  return (
    <section class="row_am map_section">
    <div class="container">
        <div class="map_inner">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.2799160891!2d-74.25987584510595!3d40.69767006338158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1664399300741!5m2!1sen!2sin" 
          style={{border:"0",width: "100%",height: "510px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
         {/* {ReactHtmlParser(mapState)} */}
         {ReactHtmlParser(data[`contactus_map_link_${lang}`])}
        </div>
    </div>
  </section>
  )
}

export default Contact3